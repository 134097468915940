import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import DTF from '../DTF';
import Cards from '../Cards';
import FormSection from '../FormSection';
import { useRef, useState } from 'react';

function Home() {
  const scrollRef = useRef()
  const [freeSamplePack, setFreeSamplePack] = useState(false);
  const [dtfImage, setDtfImage] = useState("");
  const [dtfImageFile, setDtfImageFile] = useState([]);
  const [dtfLength, setDtfLength] = useState([]);

  return (
    <>
      <HeroSection 
      scrollRef={scrollRef}
      freeSamplePack={freeSamplePack}
      setFreeSamplePack={setFreeSamplePack}
      dtfImage={dtfImage}
      setDtfImage={setDtfImage}
      dtfImageFile={dtfImageFile}
      setDtfImageFile={setDtfImageFile}
      dtfLength={dtfLength}
      setDtfLength={setDtfLength}
      />
      <DTF/>
      <Cards/>
      <div ref={scrollRef}>
      <FormSection
      freeSamplePack={freeSamplePack}
      setFreeSamplePack={setFreeSamplePack}
      dtfImage={dtfImage}
      setDtfImage={setDtfImage}
      dtfImageFile={dtfImageFile}
      setDtfImageFile={setDtfImageFile}
      dtfLength={dtfLength}
      setDtfLength={setDtfLength}
      />
      </div>
    </>
  );
}

export default Home;