import React, { useRef, useState } from "react";
import "./FormSection.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";

//Űrlap
let dtfLengthArray = [];

const FormSection = ({freeSamplePack, setFreeSamplePack, dtfImage, setDtfImage, dtfImageFile, setDtfImageFile, dtfLength, setDtfLength}) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [email, setEmail] = useState("");
  const [telNum, setTelNum] = useState("");

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [shipping, setShipping] = useState("");
  const [message, setMessage] = useState("");
  const [buttonText, setButtonText] = useState("Rendelés leadása");
  const [progress, setProgress] = useState(0);

  const inputRef = useRef(null);
  const form = useRef();
  const [load, setLoading] = useState(false);

  // Submission using Axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    //ORDER
    if (freeSamplePack === false) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate
      .getDate()
      .toString()
      .padStart(2, "0");
    const hours = currentDate
      .getHours()
      .toString()
      .padStart(2, "0");
    const minutes = currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0");
    const seconds = currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0");

    const formattedTime = `${year}${month}${day}${hours}${minutes}${seconds}`;

    if (dtfImage && dtfLength && name && email && telNum && country && city && address && zip && dtfImageFile) {
      for (const image of dtfImageFile) {
        formData.append("Image", image);
      }
      formData.append("freeSamplePack", freeSamplePack)
      formData.append("name", name);
      formData.append("date", formattedTime);
      formData.append("company", company);
      formData.append("companyName", companyName);
      formData.append("taxNumber", taxNumber);
      formData.append("email", email);
      formData.append("telNum", telNum);
      formData.append("length", dtfLength);

      formData.append("country", country);
      formData.append("city", city);
      formData.append("address", address);
      formData.append("zip", zip);
      formData.append("shipping", shipping);
      formData.append("message", message);

      setButtonText("Küldés folyamatban...");
      setLoading(true);

      try {
        const response = await axios.post(
          "https://dtf-print.onrender.com/send_email" /*'https://mmstore.hu/alexserver'*/ /*'http://localhost:5000/send_email'*/,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              setProgress(progress);
            }, // Progress callback
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle success
        alert("Rendelés sikeresen elküldve!");
        console.log(response);

        setDtfImage("")
        setDtfImageFile([])
        setDtfLength([]);
        setName("");
        setCompanyName("");
        setTaxNumber("");
        setEmail("");
        setTelNum("");
        setCountry("");
        setCity("");
        setAddress("");
        setZip("");
        setShipping("");
        setMessage("");

        setButtonText("Rendelés leadása");
        setProgress(0);
        setLoading(false);
      } catch (error) {
        // Handle error
        alert("Hiba történt!");
        setButtonText("Rendelés leadása");
        setProgress(0);
        setLoading(false);
      }
    } else {
      alert("Tölts ki minden mezőt a rendelés leadásához!");
      console.log("Tölts ki minden mezőt a rendelés leadásához!");
    }
  }

  //FREE SAMPLE PACK
  if (freeSamplePack === true) {
    if (name && email && telNum && country && city && address && zip) {;

      formData.append("freeSamplePack", freeSamplePack)
      formData.append("name", name);
      formData.append("company", company);
      formData.append("companyName", companyName);
      formData.append("taxNumber", taxNumber);
      formData.append("email", email);
      formData.append("telNum", telNum);

      formData.append("country", country);
      formData.append("city", city);
      formData.append("address", address);
      formData.append("zip", zip);
      formData.append("message", message);

      setButtonText("Küldés folyamatban...");
      setLoading(true);

      try {
        const response = await axios.post(
          "https://dtf-print.onrender.com/send_email" /*'https://mmstore.hu/alexserver'*/ /*'http://localhost:5000/send_email'*/,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              setProgress(progress);
            }, // Progress callback
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle success
        alert("Rendelés sikeresen elküldve!");
        console.log(response);

        setDtfImage("")
        setDtfImageFile([]);
        setDtfLength([]);
        setName("");
        setCompanyName("");
        setTaxNumber("");
        setEmail("");
        setTelNum("");
        setCountry("");
        setCity("");
        setAddress("");
        setZip("");
        setShipping("");
        setMessage("");

        setButtonText("Rendelés leadása");
        setProgress(0);
        setLoading(false);
      } catch (error) {
        // Handle error
        alert("Hiba történt!");
        setButtonText("Rendelés leadása");
        setProgress(0);
        setLoading(false);
      }
    } else {
      alert("Tölts ki minden mezőt a rendelés leadásához!");
      console.log("Tölts ki minden mezőt a rendelés leadásához!");
    }
  }
  };

  //Feltöltött minta képe
  const handleImageChange = (event) => {
    var fileLabel = document.getElementById("file-name");
    if (event.target.files.length > 0 && event.target.files.length <= 10) {
      fileLabel.innerText = "";
    } else {
      fileLabel.innerText = "Nincs fájl kiválasztva!";
    }
    if (event.target.files.length <= 10) {
      const selectedFiles = Array.from(event.target.files);
      setDtfImageFile(selectedFiles);
      setDtfImage(event.target.files[0]);
      dtfLengthArray = [];
      setDtfLength([]);
    } else {
      alert("Maximum 10 fájl tölthető fel!");
      event.target.value = null;
      setDtfImageFile("");
      setDtfImage("");
      dtfLengthArray = [];
      return;
    }
  };

  const CalculateLength = (event, id) => {
    dtfLengthArray[id] = event.target.value;
    setDtfLength(dtfLengthArray);
  };

  return (
    <div className="form-container">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="form-elements">
        <h2>Rendelés leadása!</h2>
        <form id="dtf-form" action="#" ref={form} onSubmit={handleSubmit}>
        <div className="form-ordertype-container">
        <div className="form-ordertype-option">
              <label>
                <input
                  name="freeSamplePack"
                  type="radio"
                  checked={freeSamplePack}
                  required
                  onChange={(e) => {
                    setFreeSamplePack(true);
                    setDtfImageFile([]);
                    setDtfImage("");
                    setDtfLength([]);
                  }}
                />
                Ingyenes mintacsomagot kérek
              </label>
            </div>
          <div className="form-ordertype-option">
              <label>
                <input
                  name="freeSamplePack"
                  type="radio"
                  checked={!freeSamplePack}
                  required
                  onChange={(e) => setFreeSamplePack(false)}
                />
                Egyedi DTF nyomatot rendelek
              </label>
            </div>
            </div>
          {freeSamplePack ? (<div>
            <label className="form-description">
              Az ingyenes mintacsomagunk előre összeállított mintákból áll, csak a felvasalható fóliát tartalmazza, melyek kizárólag a tesztelés célját szolgálják.
            </label>
          </div>):(
          <div>
            <label htmlFor="file-upload" className="form-description">
              Kérem a mintát legalább 300 dpi felbontásban átlátszó háttérrel
              .png formátumban töltse fel! A legkissebb rendelhető mennyiség 1m.
              Nagyobb mennyiség vagy rendszeres rendelések esetén egyedi
              viszonteladói kedvezmény! Ha nagyobb méretű, esetleg más formátumú
              anyagot szeretne feltölteni, esetleg a minta feltöltése vagy
              elküldése sikertelen, akkor kérem keressen meg minket
              elérhetőségeink egyikén!
            </label>
            <label htmlFor="file-upload">Minta feltöltése (Maximum 10 fájl):</label>
            <label className="form-fileupload-button" htmlFor="file-upload">
              Fájlok kiválasztása
            </label>
            <input
              className="form-fileupload"
              type="file"
              id="file-upload"
              accept="image/*"
              ref={inputRef}
              onChange={(e) => handleImageChange(e)}
              multiple
              required={!freeSamplePack}
              name="dtfImage"
            ></input>
            <div className="form-fileupload-text" id="file-name">
              Nincs fájl kiválasztva!
            </div>
            <h3 className="final_price_h3">6000 Ft / méter</h3>
            <p className="final_price_p">+Áfa +szállítási költség</p>
            {
              <div className="dtf-image">
                {(() => {
                  let images = [];
                  if (dtfImageFile) {
                    for (let i = 0; i <= dtfImageFile.length - 1; i++) {
                      images.push(
                        <section key={i}>
                          <img
                            src={URL.createObjectURL(dtfImageFile[i])}
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                            alt="dtfImage"
                            loading="lazy"
                          />
                          <label>Tekercs hossza (m)</label>
                          <input
                            type="number"
                            name="dtfLength"
                            onWheel={(e) => e.target.blur()}
                            min="1"
                            onKeyPress={(e) => {
                              if (
                                e.charCode !== 8 &&
                                e.charCode !== 0 &&
                                (e.charCode < 48 || e.charCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onInput={(e) => {
                              if (!e.target.validity.valid) {
                                e.target.value = '';
                              }
                            }}
                            step="1"
                            required={!freeSamplePack}
                            id={i}
                            onChange={(e) => CalculateLength(e, e.target.id)}
                          />
                        </section>
                      );
                    }
                  }
                  return images;
                })()}
              </div>
            }
          </div>)}                 
          <label htmlFor="name">Megrendelő neve</label>
          <input
            name="name"
            id="name"
            type="name"
            placeholder="Megrendelő neve"
            autoComplete="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="form-company-container">
          <div className="form-company-option">
            <label>
              <input
                type="checkbox"
                name="company-checkbox"
                value={company}
                onChange={(e) => setCompany(!company)}
              />
              Céges vásárlás ?
            </label>
            </div>
          </div>
          {company ? (
            <div>
              <label>Cég neve</label>
              <input
                name="companyName"
                type="companyName"
                placeholder="Cég neve"
                required={company}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <label>Adószám</label>
              <input
                name="taxNumber"
                type="taxNumber"
                placeholder="Adószám"
                required={company}
                value={taxNumber}
                onChange={(e) => setTaxNumber(e.target.value)}
              />
            </div>
          ) : (
            <div></div>
          )}
          <label htmlFor="email">Email cím megadása</label>
          <input
            name="email"
            id="email"
            type="email"
            placeholder="Email cím"
            autoComplete="on"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="tel">Telefonszám</label>
          <input
            name="tel"
            id="tel"
            type="tel"
            placeholder="Telefonszám"
            autoComplete="tel"
            required
            value={telNum}
            onChange={(e) => setTelNum(e.target.value)}
          />
          <label htmlFor="country">Szállítási adatok</label>
          <div className="form-address-container">
            <div className="form-address-items">
              <input
                name="country"
                id="country"
                type="text"
                placeholder="Ország"
                autoComplete="country-name"
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <input
                name="postcode"
                type="number"
                placeholder="Irányítószám"
                autoComplete="postal-code"
                required
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>
            <div className="form-address-items">
              <input
                name="city"
                type="text"
                placeholder="Város"
                autoComplete="address-level2"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <input
                name="address"
                type="text"
                placeholder="Utca, házszám"
                autoComplete="address-line1"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          {freeSamplePack ? (<div></div>) : 
          (<div>
          <label htmlFor="futar">Átvétel módja</label>
          <div className="form-shipping-container">
            <div className="form-shipping-option">
              <label>
                <input
                  name="radio"
                  id="futar"
                  type="radio"
                  required={!freeSamplePack}
                  value="Futár"
                  onChange={(e) => setShipping(e.target.value)}
                />
                Futár
              </label>
            </div>
            <div className="form-shipping-option">
              <label>
                <input
                  name="radio"
                  id="szemelyes_atvetel"
                  type="radio"
                  required={!freeSamplePack}
                  value="Személyes átvétel"
                  onChange={(e) => setShipping(e.target.value)}
                />
                Személyes átvétel
              </label>
            </div>
          </div>
          </div>)}
          <label htmlFor="message">Megjegyzés a megrendeléshez</label>
          <textarea
            className="form-message"
            name="message"
            id="message"
            placeholder="Megjegyzés a megrendeléshez"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="form-btns">
            <button type="submit" className="form-btns-submit">
              {buttonText}
            </button>
          </div>
          <div className="progress-container">
            <div
              className="progress-bar"
              style={{
                borderRadius: "10px",
                backgroundColor: "rgb(0, 200, 0)",
                height: "20px",
                width: `${progress}%`,
              }}
            ></div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormSection;
